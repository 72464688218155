.root {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
}

.root > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
