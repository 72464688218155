.root {
    background-color: rgb(255, 255, 255, 0.7);
    backdrop-filter: blur(20px) saturate(150%) contrast(106%) brightness(100%);
    -webkit-backdrop-filter: blur(20px) saturate(150%) contrast(106%)
        brightness(100%);
    padding: var(--spacing--md) 0;
    position: sticky;
    top: 0;
    z-index: 1;
}

.content {
    display: flex;
    flex-direction: row;
}

.end {
    flex-grow: 1;
    text-align: right;
}

@media (prefers-color-scheme: dark) {
    .root {
        background-color: rgb(31, 31, 31, 0.7);
        backdrop-filter: blur(20px) saturate(150%) contrast(106%)
            brightness(70%);
        -webkit-backdrop-filter: blur(20px) saturate(150%) contrast(106%)
            brightness(70%);
    }
}
