.root,
.root:after {
    border-radius: 50%;
    width: 5vw;
    height: 5vw;
}
.root {
    margin: auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 6px solid var(--color--accent);
    border-right: 6px solid var(--color--accent);
    border-bottom: 6px solid var(--color--accent);
    border-left: 6px solid rgba(0, 0, 0, 0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: SpinnerAnimation 1s infinite linear;
    animation: SpinnerAnimation 1s infinite linear;
}
@-webkit-keyframes SpinnerAnimation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes SpinnerAnimation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
