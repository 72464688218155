.root {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: var(--spacing--md) 0;
    background: blue;
    background-color: rgb(255, 255, 255, 0.7);
    backdrop-filter: blur(20px) saturate(150%) contrast(106%) brightness(100%);
    -webkit-backdrop-filter: blur(20px) saturate(150%) contrast(106%)
        brightness(100%);
    z-index: 1;
}

.content {
    display: flex;
    width: 100%;
}

.button {
    order: 2;
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: center;
}

.button p {
    padding: 0;
}

.root a,
.root a:hover,
.root a:active,
.root a:visited {
    color: var(--font-color);
    font-weight: 600;
}

@media (prefers-color-scheme: dark) {
    .root {
        background-color: rgb(31, 31, 31, 0.7);
        backdrop-filter: blur(20px) saturate(150%) contrast(106%)
            brightness(70%);
        -webkit-backdrop-filter: blur(20px) saturate(150%) contrast(106%)
            brightness(70%);
    }
}
