.root {
    text-align: center;
}

.image {
    width: 100%;
    max-width: 400px;
    margin-top: 2vh;
}

.title,
.subtitle {
    font-weight: 700;
    font-size: 1.75em;
    line-height: 1.15;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    white-space: pre-line;
    text-align: center;
    padding-left: var(--spacing--lg);
    padding-right: var(--spacing--lg);
}

.copy {
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    font-size: 17px;
    line-height: 1.47059;
    font-weight: 400;
    letter-spacing: -0.02em;
    padding: 0 var(--spacing--lg);
}

.title {
    color: var(--color--accent);
    margin-top: 5vh;
    margin-bottom: 2px;
}

@media (min-width: 768px) {
    .title {
        margin-top: 7vh;
    }

    .title,
    .subtitle {
        font-size: 2.5em;
        letter-spacing: -0.03em;
        line-height: 1.2;
    }
    .copy {
        max-width: 600px;
    }
}

@media (min-width: 1000px) {
    .image {
        max-width: 1000px;
        margin-top: 5vh;
    }

    .copy {
        font-size: 21px;
        line-height: 1.381;
        max-width: 600px;
    }
}

@media (min-width: 1170px) {
    .title {
        margin-top: 8vh;
    }

    .title,
    .subtitle {
        font-size: 2.5em;
        letter-spacing: -0.03em;
        line-height: 1.2;
    }

    .copy {
        max-width: 800px;
        font-size: 24px;
        line-height: 1.33341;
    }
}

@media (min-width: 1441px) {
    .title,
    .subtitle {
        font-size: 2.66666em;
        letter-spacing: -0.03em;
        line-height: 1.1;
    }
}
