.root {
    background-color: var(--color--accent);
    padding: var(--spacing--md) 0;
}

.root a,
.root a:hover,
.root a:active,
.root a:visited {
    display: block;
    text-decoration: none;
}

.root strong {
    color: var(--font-color--light-strong);
}

.main {
    display: flex;
}

.appStore {
    margin-left: auto;
    order: 2;
    display: none;
}

.title {
    color: var(--font-color--light-strong);
    font-size: 14px;
    margin-bottom: 2px;
    margin: 0;
    padding: 0;
}

.description {
    color: var(--font-color--light);
    margin: 0;
    padding: 0;
    font-size: 14px;
}

@media (min-width: 768px) {
    .appStore {
        display: initial;
        height: 36px;
    }
}
