.root {
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.root > * {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.title {
    color: var(--color--accent);
    font-weight: 700;
    font-size: 1.75em;
    line-height: 1.15;
    letter-spacing: 0.02em;
    white-space: pre-line;
    text-align: center;
    margin-top: var(--spacing--lg);
    margin-bottom: var(--spacing--md);
}

.wrapper {
    margin-bottom: var(--spacing--lg);
}

.getStarted {
    text-align: center;
}

@media (min-width: 768px) {
    .root {
        flex-flow: row;
    }

    .root > * {
        max-width: 350px;
        margin-left: var(--spacing--md);
        margin-right: var(--spacing--md);
    }

    .title {
        line-height: 1.25;
        margin-bottom: var(--spacing--lg);
    }
    .wrapper {
        margin-bottom: 100px;
    }
}

@media (min-width: 1170px) {
    .title {
        font-size: 2.5em;
        letter-spacing: -0.03em;
        line-height: 1.2;
    }
}
