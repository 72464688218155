.root {
    background-image: url(../../resources/000-VAT-App-Logo.png);
    background-size: cover;
    width: 117px;
    height: 40px;
    display: inline-block;
}

@media (prefers-color-scheme: dark) {
    .root {
        background-image: url(../../resources/000-VAT-App-Logo--Dark.png);
    }
}
