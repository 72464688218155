.qrCodeWrapper {
    width: 100%;
}

.qrCode {
    padding: var(--spacing--lg);
    border-radius: var(--spacing--lg);
    background: #f5f5f5;
    display: inline-block;
    margin-top: var(--spacing--lg);
    margin-bottom: 60px;
}

@media (min-width: 768px) {
    .qrCode {
        padding: var(--spacing--lg);
        border-radius: var(--spacing--lg);
        background: #f5f5f5;
        display: inline-block;
        margin-top: var(--spacing--lg);
        margin-bottom: var(--spacing--lg);
    }
}
