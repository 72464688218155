.root {
    display: flex;
    padding-bottom: var(--spacing--lg);
}

.content {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
}
.image > * {
    margin-top: var(--spacing--xl);
    margin-bottom: var(--spacing--xl);
}
.image img {
    width: 100%;
    padding: 5vh 0;
    margin-top: 0;
    margin-bottom: 0;
}

.title {
    color: var(--color--accent);
}

.description .LinkButton,
.description .LinkButton {
    margin-top: var(--spacing--md);
}

.title,
.subtitle {
    font-weight: 700;
    font-size: 1.75em;
    line-height: 1.15;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    white-space: pre-line;
}

.subtitle {
    line-height: 1.1;
    margin-bottom: 0.5em;
}

.description {
    font-size: var(--font-size--md);
    line-height: 1.5;
}

.root.inverted {
    background-color: var(--background-color--light);
}

@media (min-width: 768px) {
    .root {
        display: flex;
        padding-bottom: 0;
    }

    .content {
        display: flex;
        flex-flow: row;
        min-height: 70vh;
    }

    .image {
        order: 1;
        display: flex;
        align-items: center;
        flex: 1 1 60%;
        max-width: unset;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .copyWrapper {
        display: flex;
        order: 0;
        align-items: center;
        flex: 1 1 40%;
        padding-right: 60px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .copy {
        max-width: 320px;
        margin: 0 auto;
    }

    .inverted .copyWrapper {
        order: 1;
        padding-left: 60px;
        padding-right: 0;
    }

    .inverted .image {
        order: 0;
    }

    .title,
    .subtitle {
        line-height: 1.25;
    }
}

@media (min-width: 1170px) {
    .content {
        min-height: 92vh;
    }

    .title,
    .subtitle {
        font-size: 2.5em;
        letter-spacing: -0.03em;
        line-height: 1.2;
    }

    .copy {
        max-width: 390px;
    }
}

@media (min-width: 1441px) {
    .content {
        min-height: 92vh;
    }
}
