:root {
    --color--accent: #0a7bff;
    --color--white: #fff;
    --spacing--xl: 38px;
    --spacing--lg: 1.75em;
    --spacing--md: 15px;
    --spacing--sm: 10px;
    --font-size--sm: 14px;
    --font-size--md: 16px;
    --font-size--lg: 45px;
    --font-color: rgba(0, 0, 0, 0.8);
    --font-color--secondary: rgba(0, 0, 0, 0.1);
    --font-color--strong: rgba(0, 0, 0, 1);
    --font-color--light: rgba(255, 255, 255, 0.8);
    --font-color--light-strong: rgba(255, 255, 255, 1);
    --bullet-color: rgba(0, 0, 0, 0.1);
    --background-color: #fff;
    --background-color--light: rgba(0, 0, 0, 0.05);
}

@media (prefers-color-scheme: dark) {
    :root {
        --background-color: rgba(0, 0, 0, 0.9);
        --font-color: rgba(255, 255, 255, 0.8);
        --font-color--secondary: rgba(255, 255, 255, 0.1);
        --font-color--strong: rgba(255, 255, 255, 1);
        --background-color--light: rgba(255, 255, 255, 0.05);
        --bullet-color: rgba(255, 255, 255, 0.1);
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: var(--font-size--md);
    background-color: var(--background-color);
    color: var(--font-color);
}

strong {
    color: var(--font-color--strong);
}

h1,
h2 {
    margin-top: 0;
    padding-top: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a,
a:hover,
a:visited,
a:active {
    color: var(--color--accent);
    text-decoration: none;
    font-weight: normal;
}

.quote {
    border-left: 5px solid var(--color--accent);
    padding: var(--spacing--md);
    background: var(--background-color--light);
    border-radius: 3px;
    font-size: var(--font-size--sm);
}

ol,
ul {
    max-width: 800px;
    counter-reset: my-awesome-counter;
    list-style: none;
    padding-left: 35px;
}

ul {
    padding-left: 20px;
}
ul li,
ol li {
    margin: 0 0 6px 0;
    counter-increment: my-awesome-counter;
    position: relative;
}

ol li::before {
    content: counter(my-awesome-counter);
    position: absolute;
    --size: 24px;
    font-size: 12px;
    font-weight: bold;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    background: var(--bullet-color);
    border-radius: 50%;
    text-align: center;
}

ul li::before {
    color: transparent;
    content: counter(my-awesome-counter);
    position: absolute;
    --size: 8px;
    font-size: 4px;
    font-weight: bold;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 9px;
    background: var(--bullet-color);
    border-radius: 50%;
    text-align: center;
}

table th {
    text-align: left;
    padding: 0 10px 0 0;
}

table td {
    padding: 0 10px 0 0;
}
