.root {
    display: block;
    max-width: 1290px;
    margin: 0 auto;
    padding: 0 var(--spacing--lg);
}

.wrapper {
    width: 100%;
}
