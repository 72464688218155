div.root,
a.root {
    color: #fff;
    background-color: var(--color--accent);
    padding: 10px 25px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    border-radius: 25px;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
}

.root strong {
    color: var(--color--white);
}

div.isSecondary,
a.isSecondary {
    color: var(--font-color);
    background-color: transparent;
    border: 2px solid var(--color--accent);
}

div.isThirdly,
a.isThirdly {
    color: var(--font-color);
    background-color: transparent;
    border: 2px solid var(--font-color--secondary);
}

.root.isSecondary strong {
    color: var(--font-color);
}
