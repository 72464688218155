.wrapper {
    border-top: 1px solid var(--background-color--light);
    margin-top: var(--spacing--md);
}

.root {
    font-size: 30px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
}

.root button,
.root a,
.root a:active,
.root a:visited {
    display: flex;
    margin: 7.5px;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background-color: var(--background-color--light);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 30px;
    color: var(--color--accent);
}

.root button {
    appearance: none;
    border: none;
    box-sizing: unset;
    outline: none;
    cursor: pointer;
}
