.pricing {
    padding-top: 7vh;
    padding-bottom: 7vh;
    background: var(--background-color--light);
    border-radius: 5px;
}
.root {
    margin: 0 var(--spacing--md) var(--spacing--md);
}
.features {
    margin: var(--spacing--lg) 0 0;
}

.price,
.title {
    font-weight: 700;
    font-size: 1.75em;
    line-height: 1.15;
    letter-spacing: 0.02em;
    text-align: center;
    width: 100%;
}
.price {
    color: var(--font-color--strong);
}
.offer,
.frequency {
    font-size: var(--font-size--sm);
    color: var(--font-color);
    text-align: center;
}

.offer strong,
.frequency strong {
    color: var(--font-color);
}

.title,
.subtitle {
    font-weight: 700;
    font-size: 1.75em;
    line-height: 1.15;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    white-space: pre-line;
}

.subtitle {
    line-height: 1.1;
    margin-bottom: 0.5em;
}

@media (min-width: 768px) {
    .title,
    .subtitle {
        line-height: 1.25;
    }

    .root {
        margin-bottom: 100px;
    }
}

@media (min-width: 1170px) {
    .title,
    .subtitle {
        font-size: 2.5em;
        letter-spacing: -0.03em;
        line-height: 1.2;
    }
}
