.root {
    text-align: center;
    padding: var(--spacing--lg) var(--spacing--md);
}

.root a {
    color: var(--font-color);
    font-weight: normal;
    margin: 0 var(--spacing--sm);
}
